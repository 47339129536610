import { lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import PrivateRoute from './PrivateRoute';

export const IndexPage = lazy(() => import('../pages/app'));
export const HotelsPage = lazy(() => import('../pages/hotels'));
export const SearchPage = lazy(() => import('../pages/search'));

export const LoginPage = lazy(() => import('../pages/login'));

export const Page404 = lazy(() => import('../pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
    
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <PrivateRoute
          element={
            <Outlet />
          }
        />
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'hotels', element: <HotelsPage /> },
        { path: 'search', element: <SearchPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

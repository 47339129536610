import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';

import { auth } from '../api/firebase'

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Set to true if user is logged in, false otherwise
    });

    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null) {
    // Still waiting for authentication state, you might want to show a loading spinner here
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <DashboardLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </DashboardLayout>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCXfY5QTyM4xAd3_sXwa_iJbb9nJGnmUic",
  authDomain: "hotelavailability-5606c.firebaseapp.com",
  projectId: "hotelavailability-5606c",
  storageBucket: "hotelavailability-5606c.appspot.com",
  messagingSenderId: "288716912303",
  appId: "1:288716912303:web:79242b0fc1f12124ea065a"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };